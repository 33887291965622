import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { usersAction } from '../../../redux/actions';

import { Redirect } from 'react-router-dom';
import './Logout.scss';

const Logout = ({ currentUser, currentUserReset }) => {
	const [auth, setAuth] = useState(!!localStorage.getItem('user'));
	const [redir, setRedirect] = useState(false);

	const location = useLocation();
	const pathname = location.pathname;

	useEffect(() => {
		if (currentUser) {
			if (currentUser._id) setAuth(true);
			else {
				setAuth(false);
				if (pathname === '/account') setRedirect(true);
			}
		} else {
			setAuth(false);
			if (pathname === '/account') setRedirect(true);
		}
	}, [currentUser]);

	const clearState = () => {
		currentUserReset();
	};

	const onMenuLogoutClick = () => {
		localStorage.removeItem('user');
		localStorage.removeItem('@@token');

		setTimeout(() => {
			clearState();
			window.location.reload();
		}, 2000);
	};

	return (
		<React.Fragment>
			{auth ? (
				<span className='account__cart' onClick={onMenuLogoutClick}>
					<i className='fal fa-sign-out' />
				</span>
			) : null}
			{redir ? <Redirect to='/' /> : null}
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		currentUser: state.users.currentUser,
	};
};

export default connect(mapStateToProps, usersAction)(Logout);
