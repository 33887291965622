import { axios } from '../../core';

import crypto from 'crypto';

import config from './config';

const createHash = (password) => {
	let res = crypto
		.pbkdf2Sync(
			password,
			config.secret,
			config.crypto.hash.iterations,
			config.crypto.hash.length,
			'sha512'
		)
		.toString('base64');

	return res;
};

const verifyUserApi = async (userData) => {
	// const auth = await axios({
	// 	method: 'post',
	// 	url: '/user/auth',
	// 	data: {
	// 		login: userData.phone,
	// 		password: userData.password ? createHash(userData.password) : null,
	// 	},
	// });

	// if (auth.data) {
	// 	await localStorage.setItem('@@token', auth.data.token);
	try {
		const user = await axios({
			method: 'post',
			url: '/user/verify',
			data: {
				phone: userData.phone,
			},
		});

		console.log('verifyUser', user);

		return user.data;
	} catch (error) {
		console.log('verifyUser error', error);
	}
};
// };

const loginUserApi = async (userData) => {
	createHash(userData.password);
	// console.log('userData', userData);
	const auth = await axios({
		method: 'post',
		url: '/user/auth',
		data: {
			login: userData.phone,
			password: createHash(userData.password),
		},
	});

	if (auth.data) {
		try {
			await localStorage.setItem('@@token', auth.data.token);
			const user = await axios({
				method: 'post',
				url: '/user/login',
				data: {
					phone: userData.phone,
					password: userData.password,
				},
				headers: {
					Authorization: `Bearer ${auth.data.token}`,
				},
			});

			return user.data;
		} catch (e) {
			if (e.response) {
				console.log(e.response);
			} else {
			}
		}
	}
};

const recoveryAccessApi = async (userData) => {
	try {
		const user = await axios({
			method: 'post',
			url: '/user/changePassword',
			data: {
				id: userData.id,
				password: userData.password,
			},
		});
		return user.data;
	} catch (e) {
		if (e.response) {
			console.log(e.response);
		} else {
		}
	}
};

const getUserApi = async () => {
	const userId = localStorage.getItem('user');
	let token = await localStorage.getItem('@@token');

	// if (userId) {
	// 	const auth = await axios({
	// 		method: 'post',
	// 		url: '/user/auth',
	// 		data: {
	// 			login: userData.phone,
	// 		password: createHash(userData.password),
	// 		},
	// 	});

	if (userId) {
		try {
			const user = await axios({
				method: 'get',
				url: `/user/getUser/${userId}`,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return user.data;
		} catch (e) {
			console.log('error', e);
		}
	}
	// } else {
	// 	return null;
	// }
};

const getUserOrdersApi = async () => {
	const userId = localStorage.getItem('user');
	let token = await localStorage.getItem('@@token');

	// if (userId) {
	// 	const auth = await axios({
	// 		method: 'post',
	// 		url: '/user/auth',
	// 		data: {
	// 			login: config.admin.login,
	// 			password: config.admin.password,
	// 		},
	// 	});

	if (userId) {
		try {
			const user = await axios({
				method: 'get',
				url: `/user/${userId}/orders`,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return user.data.orders;
		} catch (e) {
			console.log('error', e);
		}
	}
	// } else {
	// 	return null;
	// }
};

const sendSMSApi = async (phone, codeWay) => {
	// console.log(phone.phone, codeWay);
	// if (codeWay === undefined) {
	// 	const user = await axios({
	// 		method: 'post',
	// 		url: `/user/sendsms`,
	// 		data: {
	// 			phone: phone.phone,
	// 		},
	// 	});

	// 	return user.data;
	// } else {
	const user = await axios({
		method: 'post',
		url: `/user/${codeWay}`,
		data: {
			phone: phone,
		},
	});

	return user.data;
	// }
};

const registerUserApi = async (userData) => {
	const user = await axios({
		method: 'post',
		url: '/register',
		data: userData,
	});

	return user.data;
};

const getUserOnOrderCart = async (userData) => {
	const verUser = await verifyUserApi(userData);

	if (verUser._id) {
		return verUser._id;
	} else {
		const regUser = await registerUserApi(userData);
		if (regUser.userId) return regUser.userId;
	}
};

const changeUserApi = async (userData) => {
	let token = await localStorage.getItem('@@token');
	// const auth = await axios({
	// 	method: 'post',
	// 	url: '/user/auth',
	// 	data: {
	// 		login: config.admin.login,
	// 		password: config.admin.password,
	// 	},
	// });

	if (token) {
		const user = await axios({
			method: 'put',
			url: `/user/${userData.id}`,
			data: userData,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		return user.data;
	}
};

export default {
	registerUserApi,
	sendSMSApi,
	verifyUserApi,
	getUserOnOrderCart,
	loginUserApi,
	getUserApi,
	getUserOrdersApi,
	recoveryAccessApi,
	changeUserApi,
};
