import { Col, Layout, Row } from 'antd';
import React from 'react';

import './Delivery.scss';

const { Content } = Layout;

const Delivery = () => {
	return (
		<Content className='delivery'>
			{/* <Row className='delivery__item'>
				<Col lg={{ span: 18, offset: 3 }}>
					<h1 className='delivery__header'>Зоны доставки</h1>
					<div style={{ position: 'relative' }}>
						<a
							href='https://yandex.ru/maps?utm_medium=mapframe&utm_source=maps'
							style={{
								color: '#eee',
								fontSize: '12px',
								position: 'absolute',
								top: '0px',
							}}
						>
							Яндекс Карты
						</a>
						<a
							href='https://yandex.ru/maps/?ll=34.089700%2C44.759871&mode=usermaps&source=constructorLink&um=constructor%3A072be475546abe3221305bcfae23ccbdcc1bbeba7f7b389eb08597f6f106bc84&utm_medium=mapframe&utm_source=maps&z=9.71'
							style={{
								color: '#eee',
								fontSize: '12px',
								position: 'absolute',
								top: '14px',
							}}
						>
							Яндекс Карты — транспорт, навигация, поиск мест
						</a>
						<iframe
							src='https://yandex.ru/map-widget/v1/?ll=34.089700%2C44.759871&mode=usermaps&source=constructorLink&um=constructor%3A072be475546abe3221305bcfae23ccbdcc1bbeba7f7b389eb08597f6f106bc84&z=9.71'
							width='100%'
							height='500'
							frameborder='1'
							allowFullScreen='true'
							style={{ position: 'relative' }}
						></iframe>
					</div>
				</Col>
			</Row> */}
			<Row className='delivery__item'>
				<Col lg={{ span: 18, offset: 3 }}>
					<h2 className='delivery__header delivery__header_margin'>
						Доставка самой вкусной еды по городу
					</h2>
					<ul className='delivery__city'>
						<li className='delivery__item'>
							<i className='fas fa-clock' />
							<p className='delivery__text'>Доставка пн-вс с 10 до 23</p>
						</li>
						<li className='delivery__item'>
							<i className='fas fa-truck' />
							<p className='delivery__text'>
								Доставка по городу заказов от 600 рублей
							</p>
						</li>
						<li className='delivery__item'>
							<i className='fas fa-ruble-sign' />
							<p className='delivery__text'>Стоимость доставки 200 рублей</p>
						</li>
					</ul>
				</Col>
			</Row>
			<Row className='delivery__item'>
				<Col lg={{ span: 18, offset: 3 }}>
					<h2 className='delivery__header delivery__header_margin'>
						Доставка самой вкусной еды по пригороду
					</h2>
					<ul className='delivery__city'>
						<li className='delivery__item'>
							<i className='fas fa-clock' />
							<p className='delivery__text'>Доставка пн-вс с 10 до 23</p>
						</li>
						<li className='delivery__item'>
							<i className='fas fa-truck' />
							<p className='delivery__text'>
								Доставка по пригороду и отдаленным районам города заказов от 700
								рублей
							</p>
						</li>
						<li className='delivery__item'>
							<i className='fas fa-ruble-sign' />
							<p className='delivery__text'>Стоимость доставки 240 рублей</p>
						</li>
					</ul>
				</Col>
			</Row>
			<Row className='delivery__item'>
				<Col lg={{ span: 22, offset: 3 }}>
					<Row type='flex' justify='start' className='delivery__items'>
						<ul className='delivery__precity'>
							<li className='delivery__precity-item'>Давыдовка</li>
							<li className='delivery__precity-item'>
								Ана-Юрт (по трассе на остановки)
							</li>
							<li className='delivery__precity-item'>
								Айкаван (по трассе на остановки)
							</li>
							<li className='delivery__precity-item'>
								СТ Горки 4 (до 27 улицы)
							</li>
							<li className='delivery__precity-item'>
								Украинка ( по трассе на остановки)
							</li>
							<li className='delivery__precity-item'>Дубки</li>
							<li className='delivery__precity-item'>Аграрное</li>
							<li className='delivery__precity-item'>Залесье</li>
							<li className='delivery__precity-item'>Фонтаны</li>
						</ul>
						<ul className='delivery__precity'>
							<li className='delivery__precity-item'>Белое</li>
							<li className='delivery__precity-item'>Грэс</li>
							<li className='delivery__precity-item'>Мирное</li>
							<li className='delivery__precity-item'>Молодежное</li>
							<li className='delivery__precity-item'>Комсомольское</li>
							<li className='delivery__precity-item'>Белоглинка</li>
							<li className='delivery__precity-item'>Богдановка</li>
							<li className='delivery__precity-item'>Ак-Мечеть</li>
							<li className='delivery__precity-item'>Каменка</li>
						</ul>
						<ul className='delivery__precity'>
							<li className='delivery__precity-item'>Аэропорт</li>
							<li className='delivery__precity-item'>Битумное</li>
							<li className='delivery__precity-item'>Совхозное</li>
							<li className='delivery__precity-item'>Акрополис</li>
							<li className='delivery__precity-item'>Хошкельды</li>
							<li className='delivery__precity-item'>
								Живописное (по трассе на остановки)
							</li>
							<li className='delivery__precity-item'>
								Молочное ( по трассе на остановки)
							</li>
							<li className='delivery__precity-item'>
								Строгановка (до остановки “Психиатрическая больница”)
							</li>
							<li className='delivery__precity-item'>
								Коллективные Сады (по трассе на остановки)
							</li>
						</ul>
					</Row>
					{/* <p className="delivery__footer">
                        Стоимость товаров из категории «Продукты питания» и акционные позиции не учитываются в минимальную сумму заказа для бесплатной доставки
                    </p> */}
					{/* <p className="delivery__footer">&nbsp;</p> */}
				</Col>
			</Row>
			<Row className='delivery__item'>
				<Col lg={{ span: 18, offset: 3 }}>
					<h2 className='delivery__header delivery__header_margin'>
						Доставка самой вкусной еды по дальнему пригороду
					</h2>
					<ul className='delivery__city'>
						<li className='delivery__item'>
							<i className='fas fa-clock' />
							<p className='delivery__text'>Доставка пн-вс с 10 до 23</p>
						</li>
						<li className='delivery__item'>
							<i className='fas fa-truck' />
							<p className='delivery__text'>
								Доставка по дальнему пригороду от 1000 рублей
							</p>
						</li>
						<li className='delivery__item'>
							<i className='fas fa-ruble-sign' />
							<p className='delivery__text'>Стоимость доставки 290 рублей</p>
						</li>
					</ul>
				</Col>
			</Row>
			<Row className='delivery__item'>
				<Col lg={{ span: 18, offset: 3 }}>
					<Row type='flex' justify='start' className='delivery__items'>
						<ul className='delivery__precity'>
							<li className='delivery__precity-item'>Чистенькое</li>
							<li className='delivery__precity-item'>Левадки</li>
							<li className='delivery__precity-item'>Новый аэропорт</li>
							<li className='delivery__precity-item'>
								Укромное (на заправку шелл)
							</li>
							<li className='delivery__precity-item'>
								Больница Семашко новая{' '}
							</li>
						</ul>
					</Row>
					<p className='delivery__footer'>
						Уважаемые клиенты! В случае, если курьер ожидает Вас на платной
						парковке, стоимость стоянки прибавляется к сумме заказ
					</p>
				</Col>
			</Row>
		</Content>
	);
};

export default Delivery;
